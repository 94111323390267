import React, { useState, useEffect, useRef, useCallback } from "react";
import { useDisclosure } from "@chakra-ui/hooks";
import {
  Box,
  Flex,
  Heading,
  Wrap,
  WrapItem,
  Spinner,
  Text,
  VStack,
  HStack,
  Avatar
} from "@chakra-ui/react";
import SingleFounder from "./SingleFounder";
import IsDesktop from "../../utils/IsDesktop";
import client from "../../libs/client";
import { BASE_URL } from "../../helpers/constant";
import ViewFounderDetails from "./ViewFounderDetails";
import Filter from "../filter/Filter";
import { ChevronDownIcon, ChevronUpIcon } from '@chakra-ui/icons'

import ConnectCard from "../../pages/founder/Dashboard/ConnectWithUserCards/ConnectCard";
import {useData} from "../../data"
import { SearchIcon } from "@chakra-ui/icons";
// import { messages } from "./messages";
import { HiChat } from "react-icons/hi";
import StyledSpinner from "../../components/spinner/Spinner";
import ChatBox from "../../pages/experts/Connections/ChatBox";
import ConnectionMessage from "../../pages/experts/Connections/ConnectionMessage"
import { useSelector } from "react-redux";
import moment from "moment";
import { useInterval } from "../../utils/useInterval";
import SearchBar from "../../components/searchbar/SearchBar";
import { Select } from "antd";
import { BsArrowLeft } from "react-icons/bs";
import ConnectionButton from "../../components/connectionButton/ConnectionButton";
import Header from "../header/Header";
import FoundersList from "./FoundersList";

const FoundersPage = () => {
  const { isOpen, onToggle } = useDisclosure();
  const [selectedFounder, setSelectedFounder] = useState({});
  const isDesktop = IsDesktop();
  const [pageNo, setPageNo] = useState(1);
  const lastResultsCount = useRef(0);
  const [founders, setFounders] = useState([]);
  const [isLoading, setIsLoading] = useState(true)
  const [filter, setFilters] = useState({})
  const [selectedUserIndex, setSelectedUserIndex] = useState(0)
  const [selectedUser, setSelectedUser] = useState(null)
  const { isOpen: viewDetailsIsOpen, onToggle: onToggleViewDetails } = useDisclosure();
  const loggedInUser = useSelector((state) => state?.user?.userInfo);
  const chatWithUserState = useSelector((state) => state?.chat?.chatInfo);
  const [recipient, setRecipient] = useState(chatWithUserState);
  const [recipientId, setRecipientId] = useState(chatWithUserState?.id);
  const [isLoadingMessages, setIsLoadingMessages] = useState(false);
  const [showMessageTray, setShowMessageTray] = useState(false)


  const [searchInput, setSearchInput] = useState("");
  const [searchData, setSearchData] = useState([]);
  const { OptGroup } = Select;

  // const { data, isLoading, refetch } = useData(`messages`);
  const [connections, setConnections] = useState([]);
  const { data: connectionsData, isLoading: connectionsLoading, refetch: refetchConnections } = useData(`users/${loggedInUser?.id}/connections?page_no=1&page_size=10`, 30000);
  const { isOpen: chatBoxIsOpen, onOpen: onOpenChatBox, onClose: onCloseChatBox, onToggle: onToggleChatBox } = useDisclosure();
  const foundersContainerRef = useRef(null)


  
  useEffect(() => {
    if (connectionsData?.data) {
      setConnections(connectionsData?.data?.connections?.sort((a, b) => moment(b?.lastMessage?.createdAt || b?.connection?.created_at).diff(moment(a?.lastMessage?.createdAt || a?.connection?.created_at))));
    }
    if (chatWithUserState) {
      openChatBox(chatWithUserState?.id, chatWithUserState);
    }
  }, [connectionsData?.data?.connections, chatWithUserState]);

  const openMessageTray = () => {
    setShowMessageTray(prev => !prev)
  }

  const openChatBox = (recipientId, recipient) => {
    setRecipientId(recipientId);
    setRecipient(recipient);
    onOpenChatBox();
  };
  const closeChatBox = () => {
    setRecipientId(null);
    setRecipient(null);
    onToggleChatBox();
  };
  const isFromNavigate = () => {
    return chatWithUserState && chatWithUserState?.id === recipientId && chatWithUserState?.connection?.accepted;
  };
  useInterval(() => {
    refetchConnections();
  }, 15000);

  const searchConnections = useCallback(
    (inputValue) => {
      setIsLoadingMessages(true);
      if (inputValue !== "") {
        setSearchData(connections?.filter((connection) => `${connection?.first_name} ${connection?.last_name}`.toLowerCase().includes(inputValue.toLowerCase())));
      }
      setIsLoadingMessages(false);
    },
    [connections]
  );

  const searchResults = searchData?.map((user) => (
    <Select.Option key={user.id}>
      <Flex alignItems="center">
        <Avatar size="sm" name={`${user.first_name} ${user.last_name}`} src={user.profile_image} />
        <Text ml={4}>{`${user.first_name} ${user.last_name}`}</Text>
      </Flex>
    </Select.Option>
  ));

  const openSearchedUserChatBox = (userId) => {
    setRecipientId(userId);
    setRecipient(connections?.find((connection) => connection?.id?.toString() === userId.toString()));
    onOpenChatBox();
  };

  const handleScroll = (scrollpos = window.innerHeight + window.scrollY , containerHeight = document.documentElement.offsetHeight) => {
    let userScrollHeight = scrollpos;
    let windowBottomHeight = containerHeight;

    if (userScrollHeight >= windowBottomHeight) {
      if (lastResultsCount.current < 1) {
        return;
      } else {
        let _pageNo = pageNo + 1
        setPageNo(_pageNo);
        getFounders({pageNumber: _pageNo})
      }
    }
  };
  const updateFilter = (data) => {
    setFilters(data)
  }

  const updateState = (data, append = true, isFetchedFromServer = true) => {
    if(isFetchedFromServer)lastResultsCount.current = data.length;
    let _founders = append ? [...founders, ...data] : [...data];
    setFounders(_founders);
    setSelectedUser((_founders || [])[0]);
    setIsLoading(false);
  };

  useEffect(() => {
    //window.addEventListener("scroll", handleScroll);

    //return () => window.removeEventListener("scroll", handleScroll)
  }, [pageNo]);

  const parseFilters = (obj) => {
    const params = {
      'companyStages':'company_stage', 
      'fundCategories': 'fund_category', 
      'locations': 'incorporation_location',
      'businessModels': 'business_model', 
      'categories': 'business_category',  
    }
    return Object.entries(obj).map(([key, val]) => `${params[key]}=${encodeURIComponent(val)}`).join('&')
  }

  const getFounders = ({filters = filter, append = true, pageNumber = 1 }) => {
    const _filters = parseFilters(filters)
    return client().get(`${BASE_URL}user-types/founder?page_no=${pageNumber}&page_size=10${_filters ? `&${_filters}` : ''}`).then((res) => {
      setIsLoading(true)
      const data = res.data.data.users;
      updateState(data, append);
    });
  }

  useEffect(() => {
    getFounders({})
  }, [])

  const onSelectUser = (id) => {
    setSelectedUserIndex(id)
  }

  useEffect(() => {
    onSelectUser(0)
}, [isLoading])

useEffect(() => {
    let user = founders[selectedUserIndex] 
    
    user && setSelectedUser(user)
}, [selectedUserIndex, isLoading])

const onDislikeCompany = (companyId) => {
  try {
    let _founders = founders.filter(user => user.company.id !== companyId) 
    updateState(_founders, false, false)
  } catch (e) {
    //console.log(e)
  }
}

  return (
    <Flex className="w-[100%] flex-col overflow-hidden ">
      <Header subTitle="Find founders and view your investment portfolio" title={"Founders"} />
      <Box className="p-4 bg-[#f0f0f0]">
        <FoundersList />
      </Box>
    
    </Flex>
  );
};
export default FoundersPage;
