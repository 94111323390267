import Header from "../../../components/header/Header";
import { Document } from 'react-pdf'
import React, { useState, useEffect, useRef, useCallback, memo, useMemo } from "react";
import { useDisclosure } from "@chakra-ui/hooks";
import {
  Box,
  Flex,
  Text,
} from "@chakra-ui/react";
import { MdOutlineClose } from "react-icons/md";
import IsDesktop from "../../../utils/IsDesktop";
import { ChevronDownIcon, ChevronUpIcon, StarIcon } from "@chakra-ui/icons";
import { useData } from "../../../data";
import StyledSpinner from "../../../components/spinner/Spinner";
import ChatBox from "../../experts/Connections/ChatBox";
import ConnectionMessage from "../../experts/Connections/ConnectionMessage";
import AdvertMessage from "../../experts/Connections/AdvertMessage";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { Select } from "antd";
import AdvertFeature from "../../founder/Dashboard/AdvertFeature";
import RequestList from "../../../components/notification/RequestLists";
import InvestorList from "../../../components/investors/InvestorsList";
import ExpertsList from "../../../components/experts/ExpertsList";
import FounderBookingsCalendar from "../../experts/Dashboard/FounderBookingsCalendar";
import { useWindowSize } from "../../../hooks/useWindowSize";
import InvestorListAlt from "../../../components/investors/InvestorsListAlt";
import ExpertsListAlt from "../../../components/experts/ExpertsListAlt";


const InvestorsPage = () => {
  const userDetails = useSelector((state) => state?.user?.userInfo);
  const loggedInUserType = userDetails?.userTypeTitle || "";
  const { data, refetch } = useData(`users/${userDetails.id}`);
  const companyDetails = useSelector((state) => state?.company?.companyInfo);
  const { isOpen, onToggle } = useDisclosure();
  const [selectedFounder, setSelectedFounder] = useState({});
  const isDesktop = IsDesktop();
  const [pageNo, setPageNo] = useState(1);
  const lastResultsCount = useRef(0);
  const [founders, setFounders] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [filter, setFilters] = useState({});
  const [selectedUserIndex, setSelectedUserIndex] = useState(0);
  const [selectedUser, setSelectedUser] = useState(null);
  const videoUrl = selectedUser?.company?.video_url;
  const [topConnectionIds, setTopConnectionIds] = useState([])
  const { isOpen: viewDetailsIsOpen, onToggle: onToggleViewDetails } =
    useDisclosure();
  const loggedInUser = useSelector((state) => state?.user?.userInfo);
  const chatWithUserState = useSelector((state) => state?.chat?.chatInfo);
  const [recipient, setRecipient] = useState(chatWithUserState);
  const [recipientId, setRecipientId] = useState(chatWithUserState?.id || null);
  const [isAdvert, setIsAdvert] = useState(false)
  const [advertId, setAdvertId] = useState(null)
  const [selectedAdvert, setSelectedAdvert] = useState(null)
  const { data: inboxAdverts, isLoading: inboxAdvertsLoading, refetch: refetchInboxAdverts } = useData(`inbox-adverts/`);
  const [isLoadingMessages, setIsLoadingMessages] = useState(false);
  const [showMessageTray, setShowMessageTray] = useState(false);
  const dispatch = useDispatch()

  const [searchInput, setSearchInput] = useState("");
  const [searchData, setSearchData] = useState([]);
  const { OptGroup } = Select;

  const [connections, setConnections] = useState([]);
  const {
    data: connectionsData,
    isLoading: connectionsLoading,
    refetch: refetchConnections,
  } = useData(`users/${loggedInUser?.id}/connections?page_no=1&page_size=10`, 10000);
  const {
    isOpen: chatBoxIsOpen,
    onOpen: onOpenChatBox,
    onClose: onCloseChatBox,
    onToggle: onToggleChatBox,
  } = useDisclosure();
  const foundersContainerRef = useRef(null);
  const sortFn = (a, b) => moment(b?.lastMessage?.createdAt || b?.connection?.created_at || b?.createdAt).diff(moment(a?.lastMessage?.createdAt || a?.connection?.created_at || a?.createdAt), 'second')
  const {
    isOpen: requestListIsOpen,
    onOpen: onOpenRequestList,
    onClose: onCloseRequestList,
    onToggle: onToggleRequestList
  } = useDisclosure()

  const windowSize = useWindowSize()

  useEffect(() => {
    if (connectionsData?.data) {
      setConnections(
        connectionsData?.data?.connections?.sort(sortFn)
      );
    }
    if (chatWithUserState) {
      openChatBox(chatWithUserState?.id, chatWithUserState);
    }
  }, [connectionsData?.data?.connections, chatWithUserState]);

  useEffect(() => {
    setTopConnectionIds(connections.slice(0, 2).map(({id}) => id))
  }, [connections.length])

  const openMessageTray = () => {
    if(userDetails?.status == "disabled") return 
    setShowMessageTray((prev) => !prev);
  };

  const openChatBox = (recipientId, recipient) => {
    setIsAdvert(false)
    setAdvertId(null)
    setSelectedAdvert(null)
    setRecipientId(recipientId);
    setRecipient(recipient);
    onOpenChatBox();
  };

  const openAdvert = (advertId, advert) => {
    // console.log("opening advert=====", advertId, advert, recipient, recipientId)
    setIsAdvert(true)
    setAdvertId(advertId)
    setSelectedAdvert(advert)
    setRecipient(null)
    setRecipientId(null)
    onOpenChatBox()
  }

  const closeChatBox = () => {
    setRecipientId(null);
    setRecipient(null);
    setAdvertId(null)
    setSelectedAdvert(null)
    onToggleChatBox();
  };

  const isFromNavigate = () => {
    return (
      chatWithUserState &&
      chatWithUserState?.id === recipientId &&
      chatWithUserState?.connection?.accepted
    );
  };


  const updateState = (data, append = true, isFetchedFromServer = true) => {
    if(isFetchedFromServer)lastResultsCount.current = data.length;
    let _founders = append ? [...(founders || []), ...data] : [...data];
    setFounders(_founders);
    setSelectedUser((_founders || [])[0]);
    setIsLoading(false);
  };


  useEffect(() => {
    if(connections?.filter((connection) => !connection.date_accepted && connection.source_user_id !== userDetails.id)?.length > 0 && windowSize > 1279){
      onOpenRequestList()
    }else{
      onCloseRequestList()
    }
  }, [connections?.length, windowSize])


  return (
    <>
      <Box className="w-[100%] flex flex-col overflow-hidden">
        <Flex
          className="w-[100%] lg:gap-2 xl:gap-5"
          flexDir={!isDesktop ? "column" : "row"}
          h={isDesktop ? "100%" : "auto"}
          w="100%"
          p={4}
          overflow="hidden"
        >
          
          <Box
            className={`hide-scroll ${
              isDesktop ? `max-h-[90vh] overflow-y-auto w-full pr-2` : ""
            }`}
          >
            <Box className="flex gap-2 flex-wrap-reverse md:flex-nowrap">
              <Box className="rounded-lg w-full max-w-[695px]">
                <AdvertFeature />
              </Box>
              {
                !requestListIsOpen ? (
                  <Box className="my-5 hidden xl:block">
                    <FounderBookingsCalendar />
                  </Box>
                ) : null
              }
            </Box>
              <Box className={`w-full ${ requestListIsOpen ? 'xl:max-w-[60vw]' : 'lg:max-w-[74vw]'}`}>
                <InvestorListAlt showViewAll={true} />
              </Box>
              <Box className={`w-full ${ requestListIsOpen ? 'xl:max-w-[60vw]' : 'lg:max-w-[74vw]'}`}>
                <ExpertsListAlt showViewAll={true} />
              </Box>
            </Box>
          { 
            requestListIsOpen && connections?.filter((connection) => !connection.date_accepted).length > 0 ? (
              <Box className="hidden bg-white xl:flex flex-col border border-[#DBE2ED] rounded-lg min-w-[270px]  min-h-max">
                <Box style={{boxShadow: '0 4px 20px 0 rgba(0, 0, 0, 0.05)'}} className="flex justify-between p-3 w-full ">
                  <Box className="flex items-center w-full gap-1.5">
                    <Text className="font-semibold text-2xl text-[#2F4B6E]">
                      Request List
                    </Text>
        
                        <Box className="bg-[#CF1137] flex items-center justify-center px-3 py-[2px] rounded-md">
                          <Text className="text-white">
                            {connections?.filter((connection) => !connection.date_accepted && connection.source_user_id !== userDetails.id).length}
                          </Text>
                        </Box>
                  </Box>
                  <Box onClick={onToggleRequestList} className="flex items-center px-1 rounded-full gap-[2px] border border-transparent cursor-pointer hover:border-gray-300 h-min hover:bg-gray-100">
                      <MdOutlineClose className="text-sm" />
                      <Text className="text-[#487F1E] text-xs">close</Text>
                    </Box>
                </Box> 
            
                <Box
                  className={`h-full hide-scroll p-3 ${
                    isDesktop ? "max-h-[100vh] overflow-y-auto" : ""
                  }`}
                >
                  <RequestList onReactToRequest={refetchConnections} />
                </Box>
              </Box>
              ) : null
           }
        </Flex>
        {
          !requestListIsOpen ? (
            <Flex
          hidden={isDesktop ? false : true}
          className="fixed hidden items-end min-w-max right-10 gap-8 z-10 bottom-0"
        >
          <Flex
            className="bg-white"
            boxShadow="0px 10px 20px rgba(0, 0, 0, 0.05)"
          >
            {chatBoxIsOpen && (
              <Box w={"480px"} h="80vh max-h-[300px]">
                <ChatBox
                  advert={selectedAdvert} 
                  advertId={advertId} 
                  isAdvert={isAdvert} 
                  isFromNavigate={isFromNavigate}
                  recipientId={recipientId}
                  setRecipient={setRecipient}
                  recipient={recipient}
                  closeChatBox={closeChatBox}
                  refetchConnections={refetchConnections}
                  setConnections={setConnections}
                />
              </Box>
            )}
          </Flex>
          <Flex
            boxShadow="0px 10px 20px rgba(0, 0, 0, 0.05)"
            className="flex-col bg-white min-w-[300px] rounded-t-md z-10"
          >
            <Flex
              bgColor="#134A70"
              className="items-center justify-between py-2 px-3 rounded-t-md cursor-pointer"
              onClick={openMessageTray}
            >
              <Text
                fontSize={{ base: 14 }}
                fontWeight={800}
                ml={{ base: "3rem", lg: 0 }}
                color="#fff"
              >
                New Conversation
              </Text>
              <Box>
                {showMessageTray ? (
                  <ChevronDownIcon w={7} h={7} color="white" />
                ) : (
                  <ChevronUpIcon w={7} h={7} color="white" />
                )}
              </Box>
            </Flex>
            <Box className="max-h-[60vh] overflow-y-auto">
              {showMessageTray ? (
                connectionsLoading ? (
                  <StyledSpinner />
                ) : (
                  <>
                    {[...(inboxAdverts?.data?.adverts || []), ...connections]?.length > 0 ? (
                      [...(inboxAdverts?.data?.adverts || []), ...connections.filter((b) => b.accepted === true)]?.sort(sortFn)?.map((connection, index) => (
                        connection?.advert_type ? <AdvertMessage advert={connection} key={index} openChatBox={openAdvert} /> : <ConnectionMessage
                            recipient={connection}
                            key={index}
                            openChatBox={openChatBox}
                          />
                        ))
                    ) : (
                      <Box className="text-center">No connections...</Box>
                    )}
                  </>
                )
              ) : (
                <Box py={1}></Box>
              )}
            </Box>
          </Flex>
        </Flex>
          ) : null
        }
      </Box>
    </>
  );
};

const Dashboard = () => {
  const isDesktop = IsDesktop();
  return (
    <Box className="bg-[#f0f0f0]">
      <Header subTitle="Find founders and view your investment portfolio" title="Dashboard" />
      <Flex
        className={`flex-col ${
          isDesktop ? "max-h-[95vh] pb-10 overflow-y-scroll" : ""
        }`}
      >
        <InvestorsPage />
      </Flex>
    </Box>
  );
};

export default Dashboard;
