import { ChevronLeftIcon, ChevronRightIcon } from "@chakra-ui/icons";
import { Box, Flex, IconButton, Text, Input } from "@chakra-ui/react";
import moment from "moment";
import { useEffect, useState } from "react";
import TimeSlots from "../TimeSlots";
import client from "../../../../libs/client";
import { BASE_URL } from "../../../../helpers/constant";
import { BsThreeDots } from "react-icons/bs";
import { useData } from "../../../../data";
import { useSelector } from "react-redux";
import SingularDateBooking from "./DateBooking";

const DailyBookkings = ({
  bookingDates = [],
  bookingSchedule = {},
  setCalendarType = () => {}
}) => {
  const [currentDate, setCurrentDate] = useState(moment());
  const [selectedDate, setSelectedDate] = useState(moment().startOf("day"));

  useEffect(()=>{
    if (selectedDate) {
      // setFetching(true);
      // client().get(`${BASE_URL}bookings/todays-booking?date=${selectedDate.format("YYYY-MM-DD")}`)
      //       .then((res) => {
      //         setSelectedDateBookings(res?.data?.data?.bookings)
      //         setFetching(false);
      //   })
    }
  },[selectedDate])
  
  const handlePrevWeekClick = () => setCurrentDate((prev) => prev.clone().subtract(1, "day"));
  const handleNextWeekClick = () => setCurrentDate((prev) => prev.clone().add(1, "day"));

  const handleDateChange = (e) => {
    const value = e.target.value;
    const date = moment(value, "YYYY-MM-DD");
    if (date.isValid()) {
      setCurrentDate(date);
      setSelectedDate(date.clone().startOf("day"));
    }
  };



  const renderDaysOfWeek = () => {
    return (
      <Box className="flex py-4 bg-[#F8FAFB]">
        <style>{` ::-webkit-scrollbar {display: none; }`}</style>
            <Box className="flex flex-col">
                <Box className="flex w-[30px] h-[1px]"></Box>
            </Box>
            <Box flex={1} className="text-center flex-col gap-1 px-2">
              <Text className="text-[#778CA2] text-sm font-medium">
                {
                  moment(currentDate).format("ddd")
                }
              </Text>
              <Text className="text-[#778CA2] text-sm font-medium">
                {
                  moment(currentDate).format("DD")
                }
              </Text>
            </Box>
      </Box>
    );
  };

  const renderCalendarDays = () => {

    return (
      <Box className="flex flex-col gap-2">
      <style>{` ::-webkit-scrollbar {display: none; }`}</style>
      <Box className="flex w-full items-start">
        <Box className="flex flex-col">
            {
                Array.from({ length: 24 }).map((_, index) => {
                    return (
                      <Box className="flex h-[60px]">

                        <Box className="w-[30px] text-[10px] font-medium text-gray-500">
                            <Text className="relative bottom-2 pl-1">
                            {
                              index > 0 ? index > 12 ? `${index - 12}PM` : `${index}AM` : ''
                            }
                            </Text>
                        </Box>
                        <Box className="w-3 border-b-2 relative top-[1px]"></Box>
                      </Box>
                    )
                })
            }
        </Box>
        <Box flex={1} className="flex w-full">
                <SingularDateBooking 
                  bookingDates={
                    bookingDates.includes(currentDate.format("MM-DD-YYYY")) ? bookingSchedule[currentDate.format("MM-DD-YYYY")] : []
                  }
                />
        </Box>

        </Box>
      </Box>
    );
  };

  return (
    <Box className="overflow-x-auto max-w-max lg:max-w-full" display="flex" flexDir="column" h="100%" justifyContent="flex-start">
      <Box className="w-full min-w-[1024px] lg:min-w-full border bg-white rounded-lg pt-4">
        <Flex className="border-b px-4 pb-5" alignItems="center" justifyContent="space-between">
          <Flex alignItems="center">
            <Text className="text-xs">
              {currentDate.format("MMM, YYYY")}
            </Text>
          </Flex>
          <Box className="flex hidden flex-col items-center">
            <Input type="date" value={selectedDate.format("YYYY-MM-DD")} onChange={handleDateChange} />
          </Box>
          <Box className="flex gap-2">
            <Box className="flex items-center rounded-lg border">
                <Box onClick={() => {setCalendarType("month")}} className={`p-2 border-r text-xs cursor-pointer text-[#778CA2]`}>
                    Month
                </Box>
                <Box onClick={() => {setCalendarType("week")}} className={`p-2 border-r text-xs cursor-pointer text-[#778CA2]`}>
                    Week
                </Box>
                <Box onClick={() => {setCalendarType("day")}} className={`p-2 text-xs cursor-pointer text-[#4D7CFE] bg-[#F8FAFB]`}>
                    Day
                </Box>
            </Box>
            <Box className="flex items-center gap-1.5">
                <IconButton rounded={"full"} p={0} bg={"transparent"} className="border" aria-label="Previous Week" icon={<ChevronLeftIcon fontSize={"1.5rem"} color="#778CA2"/>} onClick={handlePrevWeekClick} size="xs" />
                <IconButton rounded={"full"} p={0} bg={"transparent"} className="border" aria-label="Next Week" icon={<ChevronRightIcon fontSize={"1.5rem"} color="#778CA2" />} onClick={handleNextWeekClick} size="xs" />
            </Box>
          </Box>
        </Flex>
        {renderDaysOfWeek()}
        {renderCalendarDays()}
      </Box>
    </Box>
  );
};
export default DailyBookkings;
