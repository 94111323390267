import React, { useEffect, useState, useRef } from "react";
import {
  Box,
  Flex,
  Text,
  VStack,
  Avatar,
  HStack,
  Divider,
  Heading,
  Image,
  useDisclosure,
  Spinner,
  Wrap,
  WrapItem,
} from "@chakra-ui/react";
import { HiOutlineArrowNarrowLeft } from "react-icons/hi";
import IsDesktop from "../../utils/IsDesktop";
import BrandButton from "../brandButton/BrandButton";
import { useData } from "../../data";
import ppt from "../../public/icons/ppt.svg";
import mp4 from "../../public/icons/mp4.svg";
import PlayVideo from "./PlayVideo";
import ViewPitchDeck from "./ViewPitchDeck";
import ConnectionButton from "../connectionButton/ConnectionButton";
import default_cover_photo from "../../public/icons/default_cover_photo.png";
import ConvertVideoLinkToEmbedLink from "../../utils/ConvertVideoLinkToEmbedLink";
import { useLocation, useSearchParams } from "react-router-dom";
import { CustomToast } from "../toast/CustomToast";
import { mutateFunction, useMutation } from "../../libs/apis";
import { useSelector } from "react-redux";
import { StarIcon } from "@chakra-ui/icons";
import moment from "moment";


const ViewFounderDetails = ({ founder, onToggle, companyId = null }) => {
  const isDesktop = IsDesktop();
  const { mutate, isLoading: mutateIsLoading} = useMutation(mutateFunction);
  const { data, refetch } = useData(`users/${founder}`, 1000 * 60 * 5, {'X-C-ID' : companyId});
  const [userData, setUserData] = useState(null);
  const [companyData, setCompanyData] = useState({});
  const { addToast } = CustomToast()
  const [searchParams, setSearchParams] = useSearchParams()
  const location = useLocation()
  const { isOpen: videoIsOpen, onToggle: onToggleVideoIsOpen } =
    useDisclosure();
  const { isOpen: viewPitchDeckIsOpen, onToggle: onTogglePitchDeck } =
    useDisclosure();
  const loggedInUserId = useSelector((state) => state?.user?.userInfo?.id);
  const [count, setCount] = useState(0)
  const intervalId = useRef(null)
  const [relatedBookings, setRelatedBookings] = useState([])

  const showPitchDeck = () => {
    onTogglePitchDeck();
  };
  const showVideo = () => {
    onToggleVideoIsOpen();
  };

  const acceptRequest =  () => {
    mutate(
      { key: `users/${loggedInUserId}/connections/${userData?.connection?.connection_id  || userData?.connection?.id}`, method: "put" }, 
      {
        onSuccess(res) {
          addToast(res);
          setSearchParams({
            userid: founder,
            usertype: 1
          })
          refetch()
        },
      }
    );
  }

  const declineRequest =  () => {
    mutate(
      { key: `users/${loggedInUserId}/declineConnections/${userData?.connection?.connection_id  || userData?.connection?.id}`, method: "put" }, 
      {
        onSuccess(res) {
          addToast(res);
          setSearchParams({
            userid: founder,
            usertype: 1
          })
          refetch()
        },
      }
    );
  }


  function resetTimeout() {
    if (intervalId.current) {
      clearTimeout(intervalId.current);
    }
  }

  useEffect(() => {
      resetTimeout()
      intervalId.current = setTimeout(() => {
          setCount(prev => prev === companyData?.additional_images?.length - 1 ? 0 : prev + 1)
      }, 3000)

      return () => { resetTimeout() }
  }, [count, companyData?.additional_images?.length])

  useEffect(() => {
    if (data?.data) {
      setUserData(data.data.user);
      setCompanyData(data.data.company);
      setRelatedBookings(data?.data?.user?.bookings || [])
    }
  }, [data]);

  useEffect(() => {
    if (userData) {
      if(location?.search?.includes("status=accept")){
        acceptRequest()
      }
      if(location?.search?.includes("status=decline")){
        declineRequest()
      }
    }
  }, [userData, location?.search]);

  

  return (
    <>
      {userData !== null ? (
        <>
          <Box
            display="flex"
            w="80px"
            cursor="pointer"
            onClick={onToggle}
            justifyContent="flex-start"
            alignItems="center"
          >
            <Box mr={2}>
              <HiOutlineArrowNarrowLeft size="35px" />
            </Box>
            <Box>
              <Text fontWeight={600}>Back</Text>
            </Box>
          </Box>
          <VStack
            pb={2}
            overflow="hidden"
            overflowY="auto"
            w="100%"
            h="auto"
            bg="white"
            borderRadius="10px"
            boxShadow="0.5px 2px 10px lightgray"
          >
            <style>{` ::-webkit-scrollbar {display: none; }`}</style>
            <Box h="200px" w="100%" bg="#134A70">
              {data?.data?.user?.cover_image && (
                <div
                  style={{
                    // border: "2px solid #ccc",
                    backgroundImage: `url(${data?.data?.user?.cover_image})`,
                    backgroundSize: "cover", // Adjust as needed (cover, contain, etc.)
                    backgroundRepeat: "no-repeat", // Adjust as needed (repeat, repeat-x, repeat-y)
                    borderRadius: "8px",
                  }}
                  // bg-[#ADCFE1]
                  className="w-[100%] h-[10rem]"
                ></div>
              )}

              {!data?.data?.user?.cover_image && (
                <Box w="100%" h="100%" overflowY="hidden" bg="blue">
                  <Image src={default_cover_photo} objectFit="fit" w="100%" />
                </Box>
              )}
            </Box>
            <Flex
              w="100%"
              flexDir={"column"}
              justifyContent={isDesktop ? "flex-start" : "center"}
              className="px-2 max-w-screen-lg"
            >
              <Box
                display="flex"
                justifyContent="center"
              >
                <Flex
                  flexDir={"column"}
                  w={"100%"}
                  mb={2}
                >
                  <Flex
                    className={`w-full flex-row justify-between`}
                  >
                    <Flex className="flex-col items-center relative bottom-20">
                      <Box
                        //mt={isDesktop ? -20 : -20}                      
                        p={1}
                        borderRadius="50%"
                        borderColor="lightgray"
                        borderWidth={2}
                      >
                        <Avatar
                          name={`${userData?.first_name} ${userData?.last_name}`}
                          src={userData?.profile_image}
                          size={isDesktop ? "2xl" : "xl"}
                        />
                      </Box>
                      <Box className="flex flex-col items-center gap-1">
                        <Text
                          className="text-center"
                          fontWeight={500}
                          fontSize={isDesktop ? "16px" : "14px"}
                        >
                          {`${userData?.first_name} ${userData?.last_name}`}
                        </Text>
                        <Text fontWeight={300} fontSize="10px">
                          Joined{" "}
                          {new Date(userData.createdAt).toLocaleDateString()}
                        </Text>
                        <Flex className="flex-row gap-1 ml-1 items-center">
                          <StarIcon h={3} w={3} color={userData?.rating > 0 ? "yellow.400" : "initial"}  />
                          <StarIcon h={3} w={3} color={userData?.rating > 1 ? "yellow.400" : "initial"} />
                          <StarIcon h={3} w={3} color={userData?.rating > 2 ? "yellow.400" : "initial"} />
                        </Flex>
                      </Box>
                    </Flex>
                    <ConnectionButton user={userData} />
                  </Flex>
                </Flex>
              </Box>
              <Box className="relative bottom-14">
                <Flex className={`justify-between w-full gap-6 ${isDesktop ? 'flex-row' : 'flex-col'}`}>
                    <Flex className={`flex-col gap-3 ${isDesktop ? 'self-center' : ''}`}>
                      <Flex
                        alignItems="start"
                        className={`flex-row gap-2 md:gap-0`}
                      >
                        <Text
                          fontSize={!isDesktop ? 12 : 14}
                          fontWeight={700}
                          color="brand.primary"
                          className="min-w-[150px] whitespace-nowrap"
                        >
                          {isDesktop ? `Company Name` : `Name`}:
                        </Text>
                        <Text
                          fontWeight={isDesktop ? 400 : 700}
                          mr={2}
                          color="#000"
                          fontSize={!isDesktop ? 12 : 14}
                          align="right"
                        >
                          {companyData?.title}
                        </Text>
                      </Flex>
                      <Flex
                        alignItems="start"
                        className={`flex-row gap-2 md:gap-0`}
                      >
                        <Text
                          fontSize={!isDesktop ? 12 : 14}
                          fontWeight={700}
                          color="brand.primary"
                          className="min-w-[150px] whitespace-nowrap"
                        >
                          {isDesktop ? `Company Stage` : `Stage`}:
                        </Text>
                        <Text
                          fontWeight={isDesktop ? 400 : 700}
                          mr={2}
                          color="#000"
                          fontSize={!isDesktop ? 12 : 14}
                        >
                          {companyData?.company_stage?.map(({title}) => title ).join(', ')}
                        </Text>
                      </Flex>
                      <Flex
                        alignItems="start"
                        className={`flex-row gap-2 md:gap-0`}
                      >
                        <Text
                          fontSize={!isDesktop ? 12 : 14}
                          fontWeight={700}
                          color="brand.primary"
                          className="min-w-[150px] whitespace-nowrap"
                        >
                          Kind of fund:
                        </Text>
                        <Text
                          fontWeight={isDesktop ? 400 : 700}
                          mr={2}
                          color="#000"
                          fontSize={!isDesktop ? 12 : 14}
                        >
                          {companyData?.fund_category?.title}
                        </Text>
                      </Flex>
                      <Flex
                        alignItems="start"
                        className={`flex-row gap-2 md:gap-0`}
                      >
                        <Text
                          fontSize={!isDesktop ? 12 : 14}
                          fontWeight={700}
                          color="brand.primary"
                          className="min-w-[150px] whitespace-nowrap"
                        >
                          Location:
                        </Text>
                        <Text
                          fontWeight={isDesktop ? 400 : 700}
                          mr={2}
                          color="#000"
                          fontSize={!isDesktop ? 12 : 14}
                        >
                          {companyData?.incorporation_location?.map(({name}) => name).join(', ')}
                        </Text>
                      </Flex>
                      <Flex
                        alignItems="start"
                        className={`flex-row gap-2 md:gap-0`}
                      >
                        <Text
                          fontSize={!isDesktop ? 12 : 14}
                          fontWeight={700}
                          color="brand.primary"
                          className="min-w-[150px] whitespace-nowrap"
                        >
                          Business model:
                        </Text>
                        <Text
                          fontWeight={isDesktop ? 400 : 700}
                          mr={2}
                          color="#000"
                          fontSize={!isDesktop ? 12 : 14}
                        >
                          {companyData?.business_model?.map(({title}) => title ).join(', ')}
                        </Text>
                      </Flex>
                      <Flex
                        alignItems="start"
                        className={`flex-row gap-2 md:gap-0`}
                      >
                        <Text
                          fontSize={!isDesktop ? 12 : 14}
                          fontWeight={700}
                          color="brand.primary"
                          className="min-w-[150px] whitespace-nowrap"
                        >
                          Category:
                        </Text>
                        <Text
                          fontWeight={isDesktop ? 400 : 700}
                          mr={2}
                          color="#000"
                          fontSize={!isDesktop ? 12 : 14}
                        >
                          {companyData?.business_category?.map(({title}) => title ).join(', ')}
                        </Text>
                      </Flex>
                    </Flex>
                    <Box className={`w-full ${isDesktop ? 'max-w-[450px]' : ''}`}>
                      <Box>
                        {companyData.video_url ? (
                          <object
                            data={ConvertVideoLinkToEmbedLink(companyData.video_url)}
                            width="100%"
                            className={`${isDesktop ? 'min-h-[400px]' : 'min-h-[200px]'}`}
                          >
                            <iframe
                              width="100%"
                              height="50%"
                              src={ConvertVideoLinkToEmbedLink(companyData.video_url)}
                              title="YouTube video player"
                              frameborder="0"
                              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                              allowFullScreen
                            ></iframe>
                          </object>
                        ) : (
                          <Text color="text-gray-600 font-semibold p-3 text-center text-sm">Video Upload Pending...</Text>
                        )}
                      </Box>
                    </Box>
                </Flex>
                {
                  companyData?.additional_images?.length > 0 ? (
                    <Flex className="items-center gap-x-10 gap-y-3 justify-between my-10 w-full flex-wrap ">
                      <Flex className="flex-col gap-4 text-xl w-full md:w-max text-center p-3">
                        <Text className="font-semibold text-2xl" >Welcome to {companyData.title}</Text>
                        <Text>Discover. Inspire. Connect.</Text>
                      </Flex>
                      <Box className="relative w-[450px] mx-auto overflow-hidden">
                          <div className='whitespace-nowrap w-[90vw] sm:w-[450px]'
                          style={{ transform: `translate3d(${-count * 100}%, 0, 0)`, transition: 'ease 1000ms', }}
                          >
                            {
                              companyData?.additional_images?.map((img, index) => {
                                return (
                                  <div key={index} className="w-[90vw] sm:w-[450px] inline-block">
                                    <img src={img} alt="Company Images" className={'w-[90vw] max-h-[450px] sm:w-[450px] sm:h-[450px] object-contain'}/>
                                  </div>
                                )
                              })
                            }   
                          </div>
                        <div className='absolute bottom-5 flex w-full mx-auto items-center gap-1 justify-center'
                          >
                            {
                              companyData?.additional_images?.map((img, index) => {
                                return (
                                  <div onClick={() => {setCount(index)}} key={index} className="h-5 w-5 p-1 border cursor-pointer border-gray-300 border-2 bg-transparent rounded-full">
                                    <div className={`w-full h-full rounded-full ${index == count ? 'bg-blue-900' : 'bg-transparent'} `} />
                                  </div>
                                )
                              })
                            }   
                          </div>
                      </Box>
                    </Flex>
                  ) : null 
                }

                <Box className="mt-10">
                  <Heading
                    fontSize={isDesktop ? 20 : 16}
                    color="brand.primary"
                    mb={2}
                  >
                    About Company
                  </Heading>
                </Box>
                <Flex className={`w-full gap-4 pt-5 pb-10 flex-col`}> 
                      <Flex
                        alignItems="start"
                        className={`flex-col md:flex-row gap-2 md:gap-0`}
                      >
                        <Text
                          fontSize={!isDesktop ? 12 : 14}
                          fontWeight={700}
                          color="brand.primary"
                          className="min-w-[150px] whitespace-nowrap"
                        >
                          Description:
                        </Text>
                        <Text
                          fontWeight={isDesktop ? 400 : 700}
                          mr={2}
                          color="#000"
                          fontSize={!isDesktop ? 12 : 14}
                        >
                          {companyData?.description}
                        </Text>
                      </Flex>
                      <Flex
                        alignItems="start"
                        className={`flex-col md:flex-row gap-2 md:gap-0`}
                      >
                        <Text
                          fontSize={!isDesktop ? 12 : 14}
                          fontWeight={700}
                          color="brand.primary"
                          className="min-w-[150px] whitespace-nowrap"
                        >
                          Problem:
                        </Text>
                        <Text
                          fontWeight={isDesktop ? 400 : 700}
                          mr={2}
                          color="#000"
                          fontSize={!isDesktop ? 12 : 14}
                        >
                          {companyData?.problem}
                        </Text>
                      </Flex>
                      <Flex
                        alignItems="start"
                        className={`flex-col md:flex-row gap-2 md:gap-0`}
                      >
                        <Text
                          fontSize={!isDesktop ? 12 : 14}
                          fontWeight={700}
                          color="brand.primary"
                          className="min-w-[150px] whitespace-nowrap"
                        >
                          Solution:
                        </Text>
                        <Text
                          fontWeight={isDesktop ? 400 : 700}
                          mr={2}
                          color="#000"
                          fontSize={!isDesktop ? 12 : 14}
                        >
                          {companyData?.solution}
                        </Text>
                      </Flex>
                      <Flex
                        alignItems="start"
                        className={`flex-col md:flex-row gap-2 md:gap-0`}
                      >
                        <Text
                          fontSize={!isDesktop ? 12 : 14}
                          fontWeight={700}
                          color="brand.primary"
                          className="min-w-[150px] whitespace-nowrap"
                        >
                          Market Growth:
                        </Text>
                        <Text
                          fontWeight={isDesktop ? 400 : 700}
                          mr={2}
                          color="#000"
                          fontSize={!isDesktop ? 12 : 14}
                        >
                          {companyData?.market_growth}
                        </Text>
                      </Flex>
                      <Flex
                        alignItems="start"
                        className={`flex-col md:flex-row gap-2 md:gap-0`}
                      >
                        <Text
                          fontSize={!isDesktop ? 12 : 14}
                          fontWeight={700}
                          color="brand.primary"
                          className="min-w-[150px] whitespace-nowrap"
                        >
                          Revenue Model:
                        </Text>
                        <Text
                          fontWeight={isDesktop ? 400 : 700}
                          mr={2}
                          color="#000"
                          fontSize={!isDesktop ? 12 : 14}
                        >
                          {companyData?.revenue_model}
                        </Text>
                      </Flex>
                      <Flex
                        alignItems="start"
                        className={`flex-col md:flex-row gap-2 md:gap-0`}
                      >
                        <Text
                          fontSize={!isDesktop ? 12 : 14}
                          fontWeight={700}
                          color="brand.primary"
                          className="min-w-[150px] whitespace-nowrap"
                        >
                          Competition:
                        </Text>
                        <Text
                          fontWeight={isDesktop ? 400 : 700}
                          mr={2}
                          color="#000"
                          fontSize={!isDesktop ? 12 : 14}
                        >
                          {companyData?.competition}
                        </Text>
                      </Flex>
                      <Flex
                        alignItems="start"
                        className={`flex-col md:flex-row gap-2 md:gap-0`}
                      >
                        <Text
                          fontSize={!isDesktop ? 12 : 14}
                          fontWeight={700}
                          color="brand.primary"
                          className="min-w-[150px] whitespace-nowrap"
                        >
                          Use of Funds: 
                        </Text>
                        <Text
                          fontWeight={isDesktop ? 400 : 700}
                          mr={2}
                          color="#000"
                          fontSize={!isDesktop ? 12 : 14}
                        >
                          {companyData?.fund_use}
                        </Text>
                      </Flex>
                      <Flex
                        alignItems="start"
                        className={`flex-col md:flex-row gap-2 md:gap-0`}
                      >
                        <Text
                          fontSize={!isDesktop ? 12 : 14}
                          fontWeight={700}
                          color="brand.primary"
                          className="min-w-[150px] whitespace-nowrap"
                        >
                          Current Investors:
                        </Text>
                        <Text
                          fontWeight={isDesktop ? 400 : 700}
                          mr={2}
                          color="#000"
                          fontSize={!isDesktop ? 12 : 14}
                        >
                          {companyData?.stakeholders}
                        </Text>
                      </Flex>
                      <Flex
                        alignItems="start"
                        className={`flex-col md:flex-row gap-2 md:gap-0`}
                      >
                        <Text
                          fontSize={!isDesktop ? 12 : 14}
                          fontWeight={700}
                          color="brand.primary"
                          className="min-w-[150px] whitespace-nowrap"
                        >
                          Customers:
                        </Text>
                        <Text
                          fontWeight={isDesktop ? 400 : 700}
                          mr={2}
                          color="#000"
                          fontSize={!isDesktop ? 12 : 14}
                        >
                          {companyData?.customer_type}
                        </Text>
                      </Flex>
                </Flex>

                <Text color="brand.primary" className="text-xl font-semibold">Bookings</Text>

                {
                  relatedBookings?.length > 0 ? relatedBookings?.map((booking) => {
                    return (
                      <Box className="border-b border-blue-400 my-6 py-2 w-full">
                          <Text>
                            <strong>Title:</strong> {booking?.expert_slot?.slot_title}
                          </Text>
                          <Text>
                            <strong>Date:</strong>  {moment(booking?.expert_slot?.slot_start_date).format('DD-MM-YYYY')}
                          </Text>
                          <Text>
                            <strong>Time:</strong>  {moment(booking?.expert_slot?.slot_start_time, "kk:mm:ss").format('hh:mm a')} - {moment(booking?.expert_slot?.slot_end_time, 'kk:mm:ss').format('hh:mm a')}
                          </Text>
                           <Text className="capitalize" > <strong>Status:</strong> {booking.status}</Text>
                      </Box>
                    )
                    }) : (
                      <Box className="my-8 hidden">
                        <Text className="text-semibold text-sm text-center text-gray-600">You currently have no bookings with this expert.</Text>
                      </Box>
                    )}
              </Box>
            </Flex>
            <Box
              px={isDesktop ? 8 : 2}
              display="flex"
              flexDir="column"
              alignItems="center"
              w={isDesktop ? "80%" : "90%"}
            >
            </Box>
            <Box w="100%" display="flex" alignItems="center" flexDir="column">
              {isDesktop ? (
                <Box w="80%" px={isDesktop ? 8 : 4} py={4}>
                  <Text
                    fontSize={isDesktop ? 20 : 16}
                    fontWeight={500}
                    color="brand.primary"
                  >
                    Pitch Deck
                  </Text>
                  {companyData?.pitch_deck ? (
                    <iframe
                      title="document viewer"
                      src={`https://docs.google.com/viewer?url=${companyData?.pitch_deck}&embedded=true&#zoom=FitH`}
                      width="100%"
                      height="600px"
                    ></iframe>
                  ) : (
                    <Text color="text-gray-600 font-semibold p-3 text-center text-sm">
                      Pitch Deck Upload Pending...
                    </Text>
                  )}
                </Box>
              ) : (
                <Box py={4} w="100%">
                  {companyData?.pitch_deck ? (
                    <object
                      width="100%"
                      height="600px"
                      data={`https://drive.google.com/viewerng/viewer?embedded=true&url=${companyData?.pitch_deck}&#zoom=FitH`}
                      type="application/pdf"
                    >
                      <iframe
                        title="document viewer"
                        src={`https://docs.google.com/viewer?url=${companyData?.pitch_deck}&embedded=true&#zoom=FitH`}
                        width="100%"
                        height="600px"
                      ></iframe>
                    </object>
                  ) : (
                    <Text color="text-gray-600 font-semibold p-3 text-center text-sm">
                      Pitch Deck Upload Pending...
                    </Text>
                  )}
                </Box>
              )}
              {/* <Box w={isDesktop ? "80%" : "100%"} px={isDesktop ? 8 : 4} py={4}>
                <Text fontSize={isDesktop ? 20 : 16} fontWeight={500} color="brand.primary">
                  Video
                </Text>
                {companyData.video_url ? (
                  <object data={ConvertVideoLinkToEmbedLink(companyData.video_url)} width="100%" height="600px">
                    <iframe width="100%" height="100%" src={ConvertVideoLinkToEmbedLink(companyData.video_url)} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                  </object>
                ) : (
                  <Text color="brand.primary">Video Upload Pending...</Text>
                )}
              </Box> */}
            </Box>
          </VStack>
        </>
      ) : (
        <Box>
          <Spinner />
        </Box>
      )}
    </>
  );
};
export default ViewFounderDetails;
