import {
  Box,
  Text,
  Drawer,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  DrawerBody,
  Flex,
  Tabs,
  TabList,
  Tab,
  TabPanels,
  TabPanel,
  Spinner,
  Avatar,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import IsDesktop from "../../utils/IsDesktop";
import { HiOutlineArrowNarrowLeft } from "react-icons/hi";
import ConnectionRequestTabs from "../connectionRequests/ConnectionRequestTabs";
import { useDispatch, useSelector } from "react-redux";
import { useData } from "../../data";
import { useNavigate } from "react-router-dom";
import BrandButton from "../brandButton/BrandButton";
import { mutateFunction, useMutation } from "../../libs/apis";
import { setChatInfo } from "../../redux-toolkit/reducers/chatSlice";
import moment from "moment";
import RequestListsTabs from "../connectionRequests/RequestListsTabs";

const RequestList = ({ isOpen, onClose = () => {}, onReactToRequest = () => {} }) => {
  const isDesktop = IsDesktop();
  const navigate = useNavigate();
  const loggedInUserId = useSelector((state) => state?.user?.userInfo?.id);
  const { mutate, isLoading } = useMutation(mutateFunction);
  const dispatch = useDispatch();

  const [otherAllConnections, setOtherAllConnections] = useState();
  const [connectionNotification, setConnectionNotification] = useState([])
  const [messageTracker, setMessageTracker] = useState({})



  const readNotification = (notificationId) => {
    mutate(
      {
        key: `users/${notificationId}/notification`,
        method: "post",
      },
      {
        onSuccess(res) {
        },
      }
    );
  };

  const fetchOtherNotificationsForUser = () => {
    mutate(
      {
        key: `users/${loggedInUserId}/notification?page_no=1&page_size=10`,
        method: "get",
      },
      {
        onSuccess(res) {
          let _messageTracker = {} 
          setOtherAllConnections(res?.data?.notifications.filter(({type, senderId, id}) => {
            if(type == 'message'){
              let {count = 0, ids = []} =  _messageTracker[senderId] || {}
              count += 1
              ids = [...ids, id]
              _messageTracker[senderId] = {
                count, ids
              }
            }
            return type == 'message' ? !_messageTracker[senderId] || _messageTracker[senderId]?.count == 1 : type !== "connection_request"
          
          }));
          setConnectionNotification(res?.data?.notifications.filter(({type}) => type === "connection_request"));

          setMessageTracker(_messageTracker)
        },
      }
    );
  };

  useEffect(() => {
    const fetchNotifications = () => {
      fetchOtherNotificationsForUser();
    };

    fetchNotifications();
    return () => {
      //clearInterval(intervalId);
    };
  }, []);

  return (
   <Box>
      {
        connectionNotification?.length > 0 ? (
          <RequestListsTabs onReactToRequest={onReactToRequest} notifications={connectionNotification.map(({type, sender, id}) => ({id, type, senderId: sender.id }))} onReadNotification={readNotification} onClose={onClose} />
        ) : (
          <Box>
            <Box className="text-center">
              <Text className="text-gray-500 text-sm font-semibold">No requests</Text>
            </Box>
          </Box>
        )
      }
   </Box>
  );
};
export default RequestList;
