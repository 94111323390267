import { ChevronLeftIcon, ChevronRightIcon } from "@chakra-ui/icons";
import { Box, Flex, IconButton, Text, Input } from "@chakra-ui/react";
import moment from "moment";
import { useEffect, useState } from "react";
import TimeSlots from "../TimeSlots";
import client from "../../../../libs/client";
import { BASE_URL } from "../../../../helpers/constant";
import { BsThreeDots } from "react-icons/bs";
import { useData } from "../../../../data";
import { useSelector } from "react-redux";
import MonthlyBookings from "./MonthlyBookings";
import SingularDateBooking from "./DateBooking";
import WeeklyBookings from "./WeeklyBookings";
import DailyBookings from "./DailyBookings";

const FounderBookingsCalendarWide = ({
  showBookingsList = false,
}) => {
  const loggedInUser = useSelector((state) => state?.user?.userInfo);
  const DAYS_OF_WEEK = [ "Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
  const [currentDate, setCurrentDate] = useState(moment());
  const [selectedDate, setSelectedDate] = useState(moment().startOf("day"));
  const [selectedDateBookings, setSelectedDateBookings] = useState([]);
  const [fetching, setFetching] = useState(false);
  const [bookingDates, setBookingDates] = useState([]);
  const [bookingSchedule, setBookingSchedule] = useState([])
  const [calendarType, setCalendarType] = useState('month')

  useEffect(()=>{
    if (selectedDate) {
    }
  },[selectedDate])
  
  useEffect(()=>{
    const _schedule = {}
    const dates = loggedInUser?.myBookings?.map((booking) => {
      const _date = moment(booking?.expert_slot?.slot_start_date).format("MM-DD-YYYY")
      if(_schedule[_date]){
        _schedule[_date] = [
          ..._schedule[_date],
          {
            ...booking.expert_slot
          }
        ]
      }else {
        _schedule[_date] = [
          {
            ...booking.expert_slot
          }
        ]
      }
      return _date
    })
    setBookingDates(dates)
    setBookingSchedule(_schedule)
  },[])

  const getBackgoundColor = (date) => {
    if(selectedDate.isSame(date, "day")) {
      return "brand.primary";
    } else if (bookingDates.includes(date.format("MM-DD-YYYY"))) {
      return "rgb(191 219 254)"
    } else {
      return "white"
    }
  }
  
  const handlePrevWeekClick = () => setCurrentDate((prev) => prev.clone().subtract(2, "week"));
  const handleNextWeekClick = () => setCurrentDate((prev) => prev.clone().add(2, "week"));

  const handleDateChange = (e) => {
    const value = e.target.value;
    const date = moment(value, "YYYY-MM-DD");
    if (date.isValid()) {
      setCurrentDate(date);
      setSelectedDate(date.clone().startOf("day"));
    }
  };

  const renderDaysOfWeek = () => {
    return (
      <Box className="px-1 flex py-4 my-2.5 bg-[#F8FAFB]">
        <style>{` ::-webkit-scrollbar {display: none; }`}</style>
        {
          DAYS_OF_WEEK.map((day, index) => (
            <Box key={`${index}`} flex={1} textAlign="center">
              <Text className="text-[#778CA2] text-sm font-medium">
                {day}
              </Text>
            </Box>
          ))
        }
      </Box>
    );
  };

  const renderCalendarDays = () => {
    const startOfWeek = currentDate.clone().startOf("week");
    const endOfWeek = currentDate.clone().endOf("week");
    const startOfSecondWeek = currentDate.clone().add(1, "week").startOf("week");
    const endOfSecondWeek = currentDate.clone().add(1, "week").endOf("week");
    const firstWeek = [];
    const secondWeek = [];

    let day = startOfWeek;
    while (day <= endOfWeek) {
      firstWeek.push(day);
      day = day.clone().add(1, "day");
    }

    day = startOfSecondWeek;
    while (day <= endOfSecondWeek) {
      secondWeek.push(day);
      day = day.clone().add(1, "day");
    }

    return (
      <Box className="flex flex-col gap-2">
      <style>{` ::-webkit-scrollbar {display: none; }`}</style>
      <Box className="flex">
        {
          firstWeek.map((day) => (
            <Box display="flex" justifyContent="center" alignItems="center" key={day.format("MM-DD-YYYY")} flex={1} textAlign="center"  >
              <Box 
                display="flex" 
                justifyContent="center" 
                alignItems="center" 
                border={selectedDate.isSame(day, "day") ? "2px solid brand.primary" : "2px solid none"} 
                className="p-2 rounded-lg"
                cursor="pointer" 
                onClick={() => setSelectedDate(day.clone().startOf("day"))} 
                bg={getBackgoundColor(day)} 
                color={
                  selectedDate.isSame(day, "day") ? "white" : moment().isAfter(day, 'day') ? "grey" : "#000"
                }
                flexDir="column"
                >
                  <Text className="text-sm font-medium">
                    {day.format("D")}
                  </Text>
                <Text className={`text-lg font-bold p-0 m-0 ${bookingDates.includes(day.format("MM-DD-YYYY")) ? 'text-[#4D7CFE]' : selectedDate.isSame(day, "day") ? "text-[#134a70]" : 'text-white'} leading-[0.2]`}>.</Text>
              </Box>
            </Box>
          ))
        }
        </Box>
        <Box className="flex">
        {
          secondWeek.map((day) => (
            <Box display="flex" justifyContent="center" alignItems="center" key={day.format("MM-DD-YYYY")} flex={1} textAlign="center"  >
              <Box 
                display="flex" 
                justifyContent="center" 
                alignItems="center" 
                border={selectedDate.isSame(day, "day") ? "2px solid brand.primary" : "2px solid none"} 
                className="p-2 rounded-lg"
                cursor="pointer" 
                onClick={() => setSelectedDate(day.clone().startOf("day"))} 
                bg={getBackgoundColor(day)} 
                color={
                  selectedDate.isSame(day, "day") ? "white" : moment().isAfter(day, 'day') ? "grey" : "#000"
                }
                flexDir="column"
              >
                <Text className="text-sm font-medium">
                  {day.format("D")}
                </Text>
                <Text className={`text-lg font-bold p-0 m-0 ${bookingDates.includes(day.format("MM-DD-YYYY")) ? 'text-[#4D7CFE]' : selectedDate.isSame(day, "day") ? "text-[#134a70]" : 'text-white'} leading-[0.2]`}>.</Text>
              </Box>
            </Box>
          ))
        }
        </Box>
      </Box>
    );
  };

  return (
    <Box className="overflow-hidden">
      {
        calendarType === "day" ? (
          <DailyBookings setCalendarType={setCalendarType} bookingSchedule={bookingSchedule} bookingDates={bookingDates} />
        ) : calendarType === "week" ? (
          <WeeklyBookings setCalendarType={setCalendarType} bookingSchedule={bookingSchedule} bookingDates={bookingDates} />
        ) : (
          <MonthlyBookings setCalendarType={setCalendarType} bookingSchedule={bookingSchedule} bookingDates={bookingDates} /> 
        )
      }
      <Box className={`${showBookingsList ? 'flex' : 'hidden'} flex-col`} mt={2} h="100%">
        <Flex justifyContent="space-between" alignItems="center">
          <Text fontWeight={600} fontSize="18px" my={4}>
            Today's Bookings
          </Text>
          <BsThreeDots size="25px" />
        </Flex>
        <Box overflowY="auto" flex={1}>
        <TimeSlots bookings={selectedDateBookings} loading={fetching}/>
        </Box>
      </Box>
    </Box>
  );
};
export default FounderBookingsCalendarWide;
