import { ChevronLeftIcon, ChevronRightIcon } from "@chakra-ui/icons";
import { Box, Flex, IconButton, Text, Input } from "@chakra-ui/react";
import moment from "moment";
import { useEffect, useState } from "react";
import TimeSlots from "./TimeSlots";
import client from "../../../libs/client";
import { BASE_URL } from "../../../helpers/constant";
import { BsThreeDots } from "react-icons/bs";
import { useData } from "../../../data";
import { useSelector } from "react-redux";

const NewDailyBookingsCalendar = ({
  showBookingsList = true
}) => {
  const loggedInUser = useSelector((state) => state?.user?.userInfo);
  const {data: bookingsData} = useData(`bookings`)
  const DAYS_OF_WEEK = [ "Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
  
  const [currentDate, setCurrentDate] = useState(moment());
  const [selectedDate, setSelectedDate] = useState(moment().startOf("day"));
  const [selectedDateBookings, setSelectedDateBookings] = useState([]);
  const [fetching, setFetching] = useState(false);
  const [bookingDates, setBookingDates] = useState([]);

  useEffect(()=>{
    if (selectedDate) {
      setFetching(true);
      client().get(`${BASE_URL}bookings/todays-booking?date=${selectedDate.format("YYYY-MM-DD")}`)
            .then((res) => {
              setSelectedDateBookings(res?.data?.data?.bookings)
              setFetching(false);
        })
    }
  },[selectedDate])
  
  useEffect(()=>{
    if (bookingsData) {
      const dates = bookingsData?.data?.bookings?.map((booking) => moment(booking?.slot_start_date).format("MM-DD-YYYY"))
      setBookingDates(dates)
    }
  },[bookingsData])

  const getBackgoundColor = (date) => {
    if(selectedDate.isSame(date, "day")) {
      return "brand.primary";
    } else if (bookingDates.includes(date.format("MM-DD-YYYY"))) {
      return "rgb(191 219 254)"
    } else {
      return "white"
    }
  }
  
  const handlePrevWeekClick = () => setCurrentDate((prev) => prev.clone().subtract(2, "week").startOf("week"));
  const handleNextWeekClick = () => setCurrentDate((prev) => prev.clone().add(2, "week").startOf("week"));

  const handleDateChange = (e) => {
    const value = e.target.value;
    const date = moment(value, "YYYY-MM-DD");
    if (date.isValid()) {
      setCurrentDate(date);
      setSelectedDate(date.clone().startOf("day"));
    }
  };

  const renderDaysOfWeek = () => {
    return DAYS_OF_WEEK.map((day, index) => (
      <Box key={`${index}`} flex={1} textAlign="center">
        <Text className="text-[#778CA2] text-sm font-medium">
          {day}
        </Text>
      </Box>
    ));
  };

  const renderCalendarDays = () => {
    const startOfWeek = currentDate.clone().startOf("week");
    const endOfWeek = currentDate.clone().endOf("week");
    const startOfSecondWeek = currentDate.clone().add(1, "week").startOf("week");
    const endOfSecondWeek = currentDate.clone().add(1, "week").endOf("week");
    const firstWeek = [];
    const secondWeek = [];

    let day = startOfWeek;
    while (day <= endOfWeek) {
      firstWeek.push(day);
      day = day.clone().add(1, "day");
    }

    day = startOfSecondWeek;
    while (day <= endOfSecondWeek) {
      secondWeek.push(day);
      day = day.clone().add(1, "day");
    }

    return (
      <Box className="flex flex-col gap-2">
      <style>{` ::-webkit-scrollbar {display: none; }`}</style>
      <Box className="flex">
        {
          firstWeek.map((day) => (
            <Box display="flex" justifyContent="center" alignItems="center" key={day.format("MM-DD-YYYY")} flex={1} textAlign="center"  >
              <Box 
                display="flex" 
                justifyContent="center" 
                alignItems="center" 
                border={selectedDate.isSame(day, "day") ? "2px solid brand.primary" : "2px solid none"} 
                className="p-2 rounded-lg"
                cursor="pointer" 
                onClick={() => setSelectedDate(day.clone().startOf("day"))} 
                bg={getBackgoundColor(day)} 
                color={
                  selectedDate.isSame(day, "day") ? "white" : moment().isAfter(day, 'day') ? "grey" : "#000"
                }
                flexDir="column"
                >
                  <Text className="text-sm font-medium">
                    {day.format("D")}
                  </Text>
                  {
                    bookingDates.includes(day.format("MM-DD-YYYY")) ? (
                    <Text className={`text-lg font-bold p-0 m-0 text-[#4D7CFE] leading-[0.2]`}>.</Text>
                    ) : null
                  }
              </Box>
            </Box>
          ))
        }
        </Box>
        <Box className="flex">
        {
          secondWeek.map((day) => (
            <Box display="flex" justifyContent="center" alignItems="center" key={day.format("MM-DD-YYYY")} flex={1} textAlign="center"  >
              <Box 
                display="flex" 
                justifyContent="center" 
                alignItems="center" 
                border={selectedDate.isSame(day, "day") ? "2px solid brand.primary" : "2px solid none"} 
                className="p-2 rounded-lg"
                cursor="pointer" 
                onClick={() => setSelectedDate(day.clone().startOf("day"))} 
                bg={getBackgoundColor(day)} 
                color={
                  selectedDate.isSame(day, "day") ? "white" : moment().isAfter(day, 'day') ? "grey" : "#000"
                }
                flexDir="column"
              >
                <Text className="text-sm font-medium">
                  {day.format("D")}
                </Text>
                {
                  bookingDates.includes(day.format("MM-DD-YYYY")) ? (
                  <Text className={`text-lg font-bold p-0 m-0 text-[#4D7CFE] leading-[0.2]`}>.</Text>
                  ) : null
                }
              </Box>
            </Box>
          ))
        }
        </Box>
      </Box>
    );
  };

  return (
    <Box display="flex" flexDir="column" h="100%" justifyContent="flex-start">
      <Box className="border rounded-lg py-4">
        <Flex className="border-b px-4 pb-5" alignItems="center" justifyContent="space-between">
          <Flex alignItems="center">
            <Text className="0.9rem font-medium">
              {currentDate.format("Do MMM, YYYY")}
            </Text>
          </Flex>
          <Box className="flex hidden flex-col items-center">
            <Input type="date" value={selectedDate.format("YYYY-MM-DD")} onChange={handleDateChange} />
          </Box>
          <Box className="flex items-center gap-1.5">
            <IconButton rounded={"full"} p={0} bg={"transparent"} className="border" aria-label="Previous Week" icon={<ChevronLeftIcon fontSize={"1.5rem"} color="#778CA2"/>} onClick={handlePrevWeekClick} size="xs" />
            <IconButton rounded={"full"} p={0} bg={"transparent"} className="border" aria-label="Next Week" icon={<ChevronRightIcon fontSize={"1.5rem"} color="#778CA2" />} onClick={handleNextWeekClick} size="xs" />
          </Box>
        </Flex>
        <Flex className="my-2.5">
          <style>{` ::-webkit-scrollbar {display: none; }`}</style>
          {renderDaysOfWeek()}
        </Flex>
        {renderCalendarDays()}
      </Box>
      <Box className={`${showBookingsList ? 'flex' : 'hidden'} flex-col`} mt={2} h="100%">
        <Flex justifyContent="space-between" alignItems="center">
          <Text fontWeight={600} fontSize="18px" my={4}>
            Today's Bookings
          </Text>
          <BsThreeDots size="25px" />
        </Flex>
        <Box overflowY="auto" flex={1}>
        <TimeSlots bookings={selectedDateBookings} loading={fetching}/>
        </Box>
      </Box>
    </Box>
  );
};
export default NewDailyBookingsCalendar;
