import React, { useState, useEffect } from "react";
import { 
  Box, 
  Button, 
  ButtonGroup, 
  Flex, Icon, 
  Image, Text, 
  useDisclosure, 
  Tooltip,
} from "@chakra-ui/react";
import Header from "../../../components/header/Header";
import calendar from "../../../public/icons/calendar.svg";
import plus from "../../../public/icons/plus.svg";
import eventarrow from "../../../public/icons/eventarrow.svg";
import ExpertTable from "../../../components/table/ExpertTable";
import TableAction from "../../../components/table/action/TableAction";
import useData from "../../../data/useData";
import Calendar from "../../../components/calendar/Calendar";
import CalendarDrawer from "../../../components/drawer/calendar/CalendarDrawer";
import { upcoming_bookings, pending_bookings, past_bookings } from "./bookings";
import IsDesktop from "../../../utils/IsDesktop";
import BookingModal from "../../../components/modal/BookingModal";
import moment from "moment";
import DailyBookingsCalendar from "./DailyBookingsCalendar";
import BrandButton from "../../../components/brandButton/BrandButton";
import { AiOutlineCalendar } from "react-icons/ai";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import NewDailyBookingsCalendar from "./NewDailyBookingsCalendar";

const tableColumns = [
  {
    key: "profile",
    title: "Profile",
  },
  {
    key: "date",
    title: "Date",
  },
  {
    key: "start_time",
    title: "Start Time",
  },
  {
    key: "end_time",
    title: "End Time",
  },
];

const buttonGroup = [
  {
    value: "upcoming",
    title: "Upcoming",
  },
  {
    value: "past",
    title: "Past",
  },
];

const Dashboard = () => {
  const navigate = useNavigate();
  const [activeIndex, setActiveIndex] = useState(0);
  const { data, isLoading, refetch } = useData(`bookings`);
  const [bookings, setBookings] = useState([]);
  const isDesktop = IsDesktop();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { isOpen: isOpenModal, onOpen: onOpenModal, onClose: onCloseModal } = useDisclosure();
  const params = useParams()
  const location = useLocation()

  useEffect(() => {
    if (data?.data) {
      switch (activeIndex) {
        case 0:
          setBookings(data?.data?.bookings?.filter((booking) => moment(booking?.slot_start_date).isSameOrAfter(moment(), 'days')));
          break;
        case 1:
          setBookings(data?.data?.bookings?.filter((booking) => moment(booking?.slot_start_date).isBefore(moment(), 'days')));
          break;
        default:
          setBookings(data?.data?.bookings?.filter((booking) => moment(booking?.slot_start_date).isSameOrAfter(moment(), 'days')));
      }
    }
  }, [activeIndex, data]);

  useEffect(() => {
    if(location?.search?.includes("?slot=open")){
      if(!isOpenModal)onOpenModal()
    }
  }, [location?.search])

  const mapBookings = (bookings = []) => {
    return bookings.map((booking, i) => ({
      ...booking,
      "": <TableAction />,
    }));
  };

  return (
    <>
      <BookingModal isOpen={isOpenModal} onClose={onCloseModal} />
      {isDesktop && <CalendarDrawer isOpen={isOpen} onClose={onClose} />}
      <Box>
        <Header title="Dashboard" />
        <Box>
          <Flex minHeight={"80vh"} flexDir={!isDesktop ? "column" : "row"}>
            <Box w={!isDesktop ? "100%" : "65%"} mb={4} p={isDesktop ? 6 : 2} px={isDesktop ? 8 : 4}>
              <Box boxShadow="10px 10px 20px rgba(54, 171, 190, 0.05)" borderRadius={10} w="full" mt={4}>
                <Flex justify="space-between" align="center" p={4}>
                  <Flex pl={isDesktop && 4} align="center" gap={4}>
                    <Box borderRadius="50%" bg="#9FA7AD" p={2}>
                      <Image src={calendar} />
                    </Box>
                    <Text color="#062F36" fontWeight={700} fontSize={36}>
                      {bookings?.length}
                    </Text>
                    <Box>
                      <Text color="#000000" fontWeight={700} fontSize={16}>
                        Total Bookings
                      </Text>
                      <Text color="#9FA7AD" fontWeight={400} fontSize={12}>
                        This month
                      </Text>
                    </Box>
                  </Flex>
                  {!isDesktop ? (
                    <Icon as={eventarrow} cursor="pointer" onClick={onOpen} />
                  ) : (
                    <Flex w="250px" justifyContent="space-between">
                      <Tooltip label="Add a new slot" placement="bottom" hasArrow bg='brand.primary'>
                        <Box>
                          <BrandButton icon={plus} text="Add Slot" onClick={onOpenModal} />
                        </Box>
                      </Tooltip>
                      <Tooltip label="View your created slots" placement="bottom" hasArrow bg='brand.primary'>
                        <Box>
                          <BrandButton icon={<AiOutlineCalendar />} text="My Slots" onClick={() => navigate("../account-settings?tab=slots")} />
                        </Box>
                      </Tooltip>
                    </Flex>
                  )}
                </Flex>
              </Box>
              {!isDesktop && (
                <Flex w="250px" justifyContent="space-between">
                  <BrandButton icon={plus} text="Add Slot" onClick={onOpenModal} />
                  <BrandButton icon={<AiOutlineCalendar />} text="My Slots" onClick={() => navigate("../account-settings?tab=slots")} />
                </Flex>
              )}
              <Flex align="center" justify="space-between" mt={8}>
                <Text color="brand.primary" fontWeight={700} fontSize={14}>
                  Bookings
                </Text>
                <ButtonGroup isAttached variant="outline" size="sm" boxShadow="0px 0px 8px 4px rgba(19, 74, 112, 0.1)">
                  {buttonGroup?.map((button, index) => (
                    <Button
                      color={activeIndex === index ? "#fff" : "brand.primary"}
                      key={index}
                      onClick={() => {
                        setActiveIndex(index);
                      }}
                      bg={activeIndex === index ? "brand.primary" : "transparent"}
                      _hover={{ bg: activeIndex === index ? "brand.primary" : "#eee" }}
                    >
                      {button.title}
                    </Button>
                  ))}
                </ButtonGroup>
              </Flex>

              <ExpertTable listType={activeIndex === 0 ? 'upcoming': 'past'} header={tableColumns} data={mapBookings(bookings)} isLoading={isLoading} borderRadius="10px" refetch={refetch}/>
            </Box>

            {/* Right column */}

            <Box w={!isDesktop ? "100%" : "35%"} boxShadow="10px 10px 20px rgba(54, 171, 190, 0.05)" borderRadius="10px 10px 0px 0px" p={2}  pt={12}>
              {/* <Calendar /> */}
              <NewDailyBookingsCalendar />
            </Box>
          </Flex>
        </Box>
      </Box>
    </>
  );
};

export default Dashboard;
