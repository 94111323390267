import { ChevronLeftIcon, ChevronRightIcon } from "@chakra-ui/icons";
import { Box, Flex, IconButton, Text, Input } from "@chakra-ui/react";
import moment from "moment";
import { useEffect, useState } from "react";
import TimeSlots from "../TimeSlots";
import client from "../../../../libs/client";
import { BASE_URL } from "../../../../helpers/constant";
import { BsThreeDots } from "react-icons/bs";
import { useData } from "../../../../data";
import { useSelector } from "react-redux";

const SingularDateBooking = ({
  bookingDates = [{
        createdAt: "2024-09-11T20:37:16.825Z",
        expert_id : 29,
        id : 59,
        slot_description: "js dknsjkd",
        slot_end_date : "2024-09-27T00:00:00.000Z",
        slot_end_time: "23:38:00",
        slot_group_id: 2,
        slot_link: "google.com",
        slot_start_date: "2024-09-27T00:00:00.000Z",
        slot_start_time: "22:36:00",
        slot_title: "First",
        status: "booked",
        updatedAt: "2024-09-11T20:39:38.818Z"
    }],
}) => {
  const [currentDate, setCurrentDate] = useState(moment());
  const [selectedDate, setSelectedDate] = useState(moment().startOf("day"));

  const colors = [
    'orange',
    'yellow',
    'green',
    'red',
    'blue',
    'gray'
  ]

  const OrangeTile = ({startTime, endTime, title, style={}}) => (
    <Box className={`flex flex-col gap-1 py-2 w-full rounded text-xs bg-orange-100`} style={style}>
        <Text className={`text-orange-600 px-2`}>
            {startTime} - {endTime}
        </Text>
        <Box className={`text-orange-600 flex w-full items-start gap-2 `}>
            <Box className="bg-orange-600 h-3 w-[2px]" />
            <Text style={{textWrap: 'nowrap'}} className="text-ellipsis max-w-[20ch] overflow-hidden pt-[2px] mt-0">{ title }</Text>
        </Box>
    </Box>
  )

  const handlePrevWeekClick = () => setCurrentDate((prev) => prev.clone().subtract(1, "month"));
  const handleNextWeekClick = () => setCurrentDate((prev) => prev.clone().add(1, "month"));

  const handleDateChange = (e) => {
    const value = e.target.value;
    const date = moment(value, "YYYY-MM-DD");
    if (date.isValid()) {
      setCurrentDate(date);
      setSelectedDate(date.clone().startOf("day"));
    }
  };

  const convertToTime = (timeString) => {
    var parts = timeString.split(":");
    var date = new Date();
    date.setHours(parts[0]);
    date.setMinutes(parts[1]);
    date.setSeconds(parts[2]);

    return date;
  }

  return (
    // <Box className="overflow-x-auto max-w-max lg:max-w-full" display="flex" flexDir="column" h="100%" justifyContent="flex-start">
    //   <Box className="w-full min-w-[1024px] lg:min-w-full border bg-white rounded-lg pt-4">
    //     <Flex className="border-b px-4 pb-5" alignItems="center" justifyContent="space-between">
    //       <Flex alignItems="center">
    //         <Text className="text-xs">
    //           {currentDate.format("DD")}
    //         </Text>
    //       </Flex>
    //     </Flex>
        
    //   </Box>
    // </Box>
    <Box className="flex w-full flex-col relative" >
        {
            bookingDates.map((booking, index) => {
                const diff = Number(moment(moment(convertToTime(booking?.slot_end_time))).diff(convertToTime(booking?.slot_start_time), "minute"))
                const pos = Number(moment(convertToTime(booking?.slot_start_time)).diff(moment().startOf('day'), "minute"))
                return (
                    <Box style={{top: `${pos}px`}} className={`absolute w-full max-w-[130px] rounded-lg`}>
                        <OrangeTile 
                            style={{height: `${diff}px`}}
                            title={booking?.slot_title}
                            startTime={
                                moment(convertToTime(booking?.slot_start_time)).format("hh:mm")
                            }
                            endTime={
                                moment(convertToTime(booking?.slot_end_time)).format("hh:mm")
                            }
                        />
                    </Box>
                )
            })
        }
        {
            Array.from({ length: 24 }).map((_, index) => {
                return (
                    <Box className="border w-full h-[60px]">
                    </Box>
                )
            })
        }
    </Box>
  );
};
export default SingularDateBooking;
