import React, { useState, useEffect, useRef, useCallback, Fragment } from "react";
import { useDisclosure } from "@chakra-ui/hooks";
import {
  Box,
  Flex,
  Spinner,
  Text,
  Avatar,
  useToast,
} from "@chakra-ui/react";
import IsDesktop from "../../utils/IsDesktop";
import client from "../../libs/client";
import { BASE_URL } from "../../helpers/constant";
import {useData} from "../../data"
import { useSelector } from "react-redux";
import moment from "moment";
import { useInterval } from "../../utils/useInterval";
import { Select } from "antd";
import { BsArrowLeft } from "react-icons/bs";
import dashboard from "../../public/icons/dashboard.svg"
import { useNavigate } from "react-router-dom";
import StartupOffer from "./StartupOffer";
import { CustomToast } from "../toast/CustomToast";
import OpenExternalLinkModal from "../modal/OpenExternalLinkModal";

const OffersList = ({
  showViewAll = false,
}) => {
  const { isOpen, onToggle } = useDisclosure();
  const [selectedFounder, setSelectedFounder] = useState({});
  const isDesktop = IsDesktop();
  const [pageNo, setPageNo] = useState(1);
  const lastResultsCount = useRef(0);
  const [offers, setOffers] = useState([]);
  const [isLoading, setIsLoading] = useState(true)
  const [filter, setFilters] = useState({})
  const [selectedUserIndex, setSelectedUserIndex] = useState(0)
  const [selectedUser, setSelectedUser] = useState(null)
  const { isOpen: viewDetailsIsOpen, onToggle: onToggleViewDetails } = useDisclosure();
  const loggedInUser = useSelector((state) => state?.user?.userInfo);
  const chatWithUserState = useSelector((state) => state?.chat?.chatInfo);
  const [recipient, setRecipient] = useState(chatWithUserState);
  const [recipientId, setRecipientId] = useState(chatWithUserState?.id);
  const [isLoadingMessages, setIsLoadingMessages] = useState(false);
  const [showMessageTray, setShowMessageTray] = useState(false)
  const [link, setLink] = useState('')
  const [offerId, setOfferId] = useState('')
  const [selectedOffer, setSelectedOffer] = useState({});
  const { addToast } = CustomToast()
  const {
    isOpen: modalIsOpen,
    onClose: onCloseModal,
    onOpen: onOpenModal
  } = useDisclosure()
  const [searchInput, setSearchInput] = useState("");
  const [searchData, setSearchData] = useState([]);
  const { OptGroup } = Select;

  // const { data, isLoading, refetch } = useData(`messages`);
  const [connections, setConnections] = useState([]);
  const { data: connectionsData, isLoading: connectionsLoading, refetch: refetchConnections } = useData(`users/${loggedInUser?.id}/connections?page_no=1&page_size=10`, 30000);
  const { isOpen: chatBoxIsOpen, onOpen: onOpenChatBox, onClose: onCloseChatBox, onToggle: onToggleChatBox } = useDisclosure();
  const offersContainerRef = useRef(null)

  const navigate = useNavigate()
  
  useEffect(() => {
    if (connectionsData?.data) {
      setConnections(connectionsData?.data?.connections?.sort((a, b) => moment(b?.lastMessage?.createdAt || b?.connection?.created_at).diff(moment(a?.lastMessage?.createdAt || a?.connection?.created_at))));
    }
    if (chatWithUserState) {
      openChatBox(chatWithUserState?.id, chatWithUserState);
    }
  }, [connectionsData?.data?.connections, chatWithUserState]);

  const openMessageTray = () => {
    setShowMessageTray(prev => !prev)
  }

  const openChatBox = (recipientId, recipient) => {
    setRecipientId(recipientId);
    setRecipient(recipient);
    onOpenChatBox();
  };
  const closeChatBox = () => {
    setRecipientId(null);
    setRecipient(null);
    onToggleChatBox();
  };
  const isFromNavigate = () => {
    return chatWithUserState && chatWithUserState?.id === recipientId && chatWithUserState?.connection?.accepted;
  };
  useInterval(() => {
    refetchConnections();
  }, 15000);

  
  const searchConnections = useCallback(
    (inputValue) => {
      setIsLoadingMessages(true);
      if (inputValue !== "") {
        setSearchData(connections?.filter((connection) => `${connection?.first_name} ${connection?.last_name}`.toLowerCase().includes(inputValue.toLowerCase())));
      }
      setIsLoadingMessages(false);
    },
    [connections]
  );

  const searchResults = searchData?.map((user) => (
    <Select.Option key={user.id}>
      <Flex alignItems="center">
        <Avatar size="sm" name={`${user.first_name} ${user.last_name}`} src={user.profile_image} />
        <Text ml={4}>{`${user.first_name} ${user.last_name}`}</Text>
      </Flex>
    </Select.Option>
  ));

  const openSearchedUserChatBox = (userId) => {
    setRecipientId(userId);
    setRecipient(connections?.find((connection) => connection?.id?.toString() === userId.toString()));
    onOpenChatBox();
  };

  const handleScroll = (scrollpos = window.innerHeight + window.scrollY , containerHeight = document.documentElement.offsetHeight) => {
    let userScrollHeight = scrollpos;
    let windowBottomHeight = containerHeight;

    if (userScrollHeight >= windowBottomHeight) {
      if (lastResultsCount.current < 1) {
        return;
      } else {
        let _pageNo = pageNo + 1
        setPageNo(_pageNo);
        getoffers({pageNumber: _pageNo})
      }
    }
  };

  const updateFilter = (data) => {
    setFilters((prev) => ({
      ...prev,
      ...data
    }))
  }

  const updateState = (data, append = true, isFetchedFromServer = true) => {
    if(isFetchedFromServer)lastResultsCount.current = data.length;
    let _offers = append ? [...offers, ...data] : [...data];
    setIsLoading(false);
    setOffers([..._offers]);
  };


  const parseFilters = (obj) => {
    const params = {
      'companyStages':'company_stage', 
      'fundCategories': 'fund_category', 
      'locations': 'incorporation_location',
      'businessModels': 'business_model', 
      'categories': 'business_category',  
    }
    return Object.entries(obj).map(([key, val]) => `${params[key]}=${encodeURIComponent(val)}`).join('&')
  }

  const getoffers = ({filters = filter, append = true, pageNumber = 1 }) => {
    const _filters =''// parseFilters(filters)
    return client().get(`${BASE_URL}offers?status=ongoing&page_no=${pageNumber}&page_size=10${_filters ? `&${_filters}` : ''}`).then((res) => {
      setIsLoading(true)
      let data = res.data.data.offers;
      updateState(data, append);
    });
  }

  useEffect(() => {
    getoffers({})
  }, [])

  const onSelectUser = (id) => {
    setSelectedUserIndex(id)
  }

  useEffect(() => {
    onSelectUser(0)
}, [isLoading])

const getDeal = (offer) => {
  setOfferId(offer.id)
        if (offer.offer_url !== null) {
          if (
            offer.offer_url.startsWith("http://") ||
            offer.offer_url.startsWith("https://")
          ) {
            //window.open(offer.offer_url);
            setLink(offer.offer_url)       
            onOpenModal()
          } else {
            //window.open("https://" + offer.offer_url);
            setLink(`https://${offer.offer_url}`)
            onOpenModal()
          }
        } else {
          addToast({ message: "No link", type: "warning" });
        }
};


  return (
    <Flex className="flex-col border bg-white mb-4 rounded-lg pb-2">
      {
        !isOpen ? (
          <>
          {
            !viewDetailsIsOpen ? (
              <Flex style={{boxShadow: '0 4px 20px 0 rgba(0, 0, 0, 0.05)'}} className="flex-row p-3 mb-4 items-start justify-between" >
                    <Box minW="100px">
                      <Text
                        color="brand.primary"
                        className={`pt-1 text-2xl font-semibold`}
                      >
                        Offers
                      </Text>
                    </Box>
                  <Box onClick={() => {navigate('/offers')}} className={`gap-2 py-1 cursor-pointer items-center font-medium hover:font-semibold ${showViewAll ? 'flex' : 'hidden'}`}>
                    <Box className="sm:pr-2 sm:border-r">
                      <img className="h-3.5 w-3.5" src={dashboard} alt='dashboard-icon'/>
                    </Box>
                    <Text className="hidden sm:block text-green-800 text-sm">View all</Text>
                  </Box>
                </Flex>
            ) : null
          }
          </>
        ) : null
      }
      <OpenExternalLinkModal isOpen={modalIsOpen} id={offerId} onClose={() => {
        onCloseModal()
        setLink('')
        setOfferId('')
      }} link={link} />
      <Box 
        ref={offersContainerRef}  
        onScroll={() => {handleScroll(offersContainerRef.current.scrollTop + offersContainerRef.current.clientHeight, offersContainerRef.current.scrollHeight)}} 
        className={`hide-scroll ${isDesktop ? 'max-h-[90vh] overflow-y-auto pt-4 md:px-5 pb-32' : ''}`} 
      >
        {!isLoading ? (
                <Box
                  h="100%"
                  className="flex flex-wrap gap-3 items-center px-4 justify-start mx-auto"
                >
                  {offers && offers.length > 0  ? offers.map((offer, index) => (
                    <Fragment key={index}>
                      <StartupOffer
                          key={offer.id}
                          getDeal={getDeal}
                          setSelectedOffer={setSelectedOffer}
                          offer={offer}
                          isOpen={isOpen}
                          onToggle={onToggle}
                        />
                    </Fragment>
                  )) : <Flex className="text-center justify-center my-2 w-full">No offers available yet!</Flex>}
                </Box>
          ) : (
                <Spinner />
            )}
      </Box>
    
    </Flex>
  );
};
export default OffersList;
